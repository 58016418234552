import React from 'react'

import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'

import '../styles/about.sass'

const JoinPage = () => {
    return (
        <Layout languageSwitchLink="/en/join">
            <SEO title="Participer" />
            <Banner class_suffix="deep" />
            <div className="about-container">
                <div className="about-content">
                 {/*    <p>
                        Rejoins notre communauté pour développer tes compétences en cybersécurité!
                    </p> */}

                    {/* Section pour les ateliers techniques */}
                    <h3>Participe aux Hacker Nights et aux ateliers techniques</h3>
                    <p>
                        Les Hacker Nights sont des événements hebdomadaires où on résout ensemble (ou pas) des défis de cybersécurité. Tous les Mardis de 18h à 21h en L-2708 !
                    </p>
                    <p>
                        Les ateliers ont pour but d'approfondir des thèmes variés de la cybersécurité, ainsi que d'améliorer les compétences des participant(e)s. Selon les ateliers, le niveau varie de débutant à avancé.
                    </p>
                    <div className="button-group">
                        <a href="https://discord.gg/ZcYnS3GZE6">
                            <button className="signup-button">Rejoins notre Discord</button>
                        </a>
                    </div>

                    {/* Section pour s'impliquer */}
                    <h3>Implique-toi davantage!</h3>
                    <p>
                        En rejoignant l'équipe exécutive ou l'équipe CTF, tu pourras t'impliquer plus activement dans les activités de notre club, que ce soit pour organiser des événements ou participer aux compétitions de cybersécurité.
                    </p>

                    {/* Conteneur pour les deux options d'implication */}
                    <div className="team-options">
                        {/* Option pour rejoindre l'équipe exécutive */}
                        <div className="team-option">
                            <h2>Équipe Exécutive</h2>
                            <p>
                                L'équipe exécutive planifie les ateliers techniques, organise des compétitions CTF (Capture-the-Flag), développe les partenariats avec l'industrie, communique les nouveaux événements et bien plus encore!
                            </p>
                            <a href="https://forms.gle/Q5PedtHzsSjAjCLn6">
                                <button className="signup-button">Rejoins notre équipe Exécutive!</button>
                            </a>
                        </div>

                        {/* Option pour rejoindre l'équipe CTF */}
                        <div className="team-option">
                            <h2>Équipe CTF</h2>
                            <p>
                                L'équipe CTF participe à des compétitions tout au long de l'année, incluant des événements prestigieux comme <a href="https://nsec.io/">NorthSec</a> et <a href="https://hfctf.ca/">HackFest</a>. Rejoignez-nous pour repousser vos limites dans des défis techniques de cybersécurité! (Vous devez être étudiant à Polytechnique Montréal.)
                            </p>
                            <a href="https://forms.gle/nRu5TeagsmhXCqFw8">
                                <button className="signup-button">Rejoins notre équipe CTF!</button>
                            </a>
                        </div>
                    </div>

                    <p>
                         Consultez notre classement sur <a href="https://ctftime.org/team/59057">CTFTime</a>.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default JoinPage
